import { GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid';

export const formatOptionalTableData = (data: any) => data.value || '-';

export const formatOptionalTableDataDate = <T extends GridValidRowModel>(
  params: GridValueGetterParams<any, T>
) => {
  const { value } = params;

  return value
    ? new Date(value).toLocaleDateString()
    : formatOptionalTableData(params);
};

export const formatDate = (milliseconds: string) => {
  if (!milliseconds) return '-';

  const date = new Date(milliseconds);
  return `${date.toLocaleDateString()}`;
};
