import {
  AccessTime,
  AccessTimeFilled,
  MonetizationOnRounded,
  QrCodeRounded,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { NFT } from 'src/lib/types/nft';
import { formatDate } from 'src/lib/utils/formatters';

type AboutData = {
  label: string;
  value: string | number;
  icon?: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
};

export default function NFTAbout({ nft }: { nft: NFT }) {
  const aboutData: AboutData[] = [
    {
      label: 'UUID',
      value: nft.uuid,
      icon: <QrCodeRounded />,
      sx: { fontSize: '12px' },
    },
    {
      label: 'Sales Price',
      value: nft.salesPrice,
      icon: <MonetizationOnRounded />,
    },
    {
      label: 'Updated At',
      value: formatDate(nft.updatedAt),
      icon: <AccessTimeFilled />,
    },
    {
      label: 'Created At',
      value: formatDate(nft.createdAt),
      icon: <AccessTime />,
    },
  ];

  return (
    <Card>
      <CardHeader title="About" />
      <CardContent>
        <Stack spacing={1}>
          {aboutData.map((data) => (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              key={data.label}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {data.icon}
                <Typography fontSize="14px">{data.label}:</Typography>
              </Stack>
              <Typography
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                sx={{ ...data.sx }}
              >
                {data.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
