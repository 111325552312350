import useSWR from 'swr';
import { Transaction } from '../types/transaction';

type FetchMovesResponse = {
  meta: {
    totalCount: number;
  };
  body: Transaction[];
};

const fetchMoves = (uuid: string) => {
  const { data, error } = useSWR<FetchMovesResponse, Error>(`moves/${uuid}`);

  return {
    moves: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export default fetchMoves;
