import { Container } from '@mui/material';
import Profile from 'src/components/modules/Profile/Profile';

export default function ProfilePage(): JSX.Element {
  return (
    <Container>
      <Profile />
    </Container>
  );
}
