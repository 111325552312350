import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchCollections } from 'src/lib/services/collections';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import useTabs from 'src/template/hooks/useTabs';
import CollectionsTable from './CollectionsTable/CollectionsTable';
import CollectionsTableFooter from './CollectionsTable/CollectionTableFooter';
import useCollectionsTable, {
  SearchForOptions,
} from './CollectionsTable/useCollectionsTable';

const REVIEW_STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'awaiting_review', label: 'Awaiting Review' },
  { value: 'reviewing', label: 'Reviewing' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

const SEARCH_OPTIONS: SearchForOptions[] = ['name', 'description', 'creator'];

export default function Collections() {
  const { currentTab: reviewStatus, onChangeTab: onChangeReviewStatus } =
    useTabs('all');

  const { orderBy, order, page, query, searchFor, size } = useCollectionsTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useCollectionsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const {
    data: collections,
    total,
    loading,
  } = fetchCollections(
    {
      query: useDebounce(query, 300),
      page,
      order,
      orderBy,
      searchFor,
      size,
    },
    reviewStatus
  );

  return (
    <>
      <HeaderBreadcrumbs
        heading="Collections"
        links={[{ name: 'Collections' }]}
      />
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={reviewStatus}
          onChange={onChangeReviewStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {REVIEW_STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search collection..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <CollectionsTable data={collections} loading={loading} />
        </Scrollbar>

        <CollectionsTableFooter
          total={total || 0}
          page={page}
          rowsPerPage={size}
        />
      </Card>
      <Scrollbar />
    </>
  );
}
