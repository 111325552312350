import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { User } from 'src/lib/types/users';
import Iconify from 'src/template/components/Iconify';
import {
  TableHeadCustom,
  TableSelectedActions,
} from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';
import UserTableRow from './UsersTableRow';
import useUsersTable from './useUsersTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'authId', label: 'AuthId', align: 'left' },
  { id: 'slug', label: 'Slug', align: 'left' },
  { id: 'login', label: 'Login', align: 'left' },
  { id: 'updatedAt', label: 'Updated At', align: 'right' },
  { id: 'createdAt', label: 'Created At', align: 'right' },
];

export default function UsersTable({
  data,
  loading,
}: {
  data: User[] | undefined;
  loading: boolean;
}) {
  const [selected, toggleSelectAll] = useUsersTable((state) => [
    state.selected,
    state.toggleSelectAll,
  ]);

  const [order, orderBy] = useUsersTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useUsersTable((state) => state.onSort);

  const dense = useUsersTable((state) => state.dense);

  const onSelectAll = () => {
    toggleSelectAll(data?.map((user) => user.uuid) || []);
  };

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      {selected.length > 0 && (
        <TableSelectedActions
          dense={dense}
          numSelected={selected.length}
          rowCount={data?.length || 0}
          onSelectAllRows={onSelectAll}
          actions={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={() => {}}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />
      )}

      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={[...TABLE_HEAD, { id: '', label: '' }]}
          rowCount={loading ? 0 : data && data.length}
          numSelected={selected.length}
          onSort={onSort}
          onSelectAllRows={onSelectAll}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((user: User) => (
              <UserTableRow key={user.uuid} user={user} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
