import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme: Theme) {
  const varLow = alpha(theme.palette.grey[900], 0.48);
  const varHigh = alpha(theme.palette.grey[900], 1);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: '#00000030',
          backdropFilter: 'blur(20px)',
          '&.MuiBackdrop-invisible, &.MuiPopover-root': {
            background: 'transparent',
            backdropFilter: 'blur(0px)',
          },
        },
      },
    },
  };
}
