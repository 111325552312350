import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { deleteRegion } from 'src/lib/services/regions';
import { LTVRegion } from 'src/lib/types/ltvRegion';

type DeleteRegionModalProps = {
  open: boolean;
  onClose: () => void;
  region: LTVRegion;
};

export default function DeleteRegionModal({
  open,
  onClose,
  region,
}: DeleteRegionModalProps) {
  // const filter = useRegionsTable((state) => state.filter);

  const onDelete = async () => {
    await deleteRegion(region.uuid);
    // mutate(`admin/regions/${generateSearchRequest(filter)}`);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Card sx={{ width: '400px' }}>
            <CardHeader title="Delete Region" />
            <CardContent>
              <Stack spacing={2} justifyContent="center">
                <Typography>
                  Are you sure you want to delete this region?
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Button color="error" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button color="error" variant="contained" onClick={onDelete}>
                    Delete
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Fade>
    </Modal>
  );
}
