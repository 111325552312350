import { Card, CardContent, CardHeader, Typography } from '@mui/material';

export default function CollectionDescription({
  description,
}: {
  description?: string;
}) {
  return (
    <Card>
      <CardHeader title="Description" />
      <CardContent>
        <Typography color={description ? 'text.primary' : 'text.secondary'}>
          {description || 'empty'}
        </Typography>
      </CardContent>
    </Card>
  );
}
