/* eslint-disable @typescript-eslint/no-unused-vars */
import { AlertTitle, Card } from '@mui/material';
import { DataGrid, getGridDateOperators, GridColumns } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import dateRangeOperator from 'src/lib/custom/dateRangeOperator';
import MOCK_USER_BONUS_RECEIVED from 'src/lib/mock/user_bonus_received';
import { BonusTypes, IBonus } from 'src/lib/types/bonus';
import { UserDetailsProfileTab } from 'src/lib/types/userDetails_tabs';

const TABLE_HEAD: GridColumns<IBonus> = [
  {
    field: 'date',
    headerName: 'Date',
    align: 'center',
    flex: 1,
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    filterOperators: [...dateRangeOperator, ...getGridDateOperators()],
  },
  {
    field: 'time',
    headerName: 'Time',
    flex: 1,
    valueGetter: ({ value }) => value && new Date(value).toLocaleTimeString(),
    align: 'center',
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    type: 'singleSelect',
    valueOptions: Object.values(BonusTypes),
    valueGetter: ({ value }) => value && capitalCase(value),
  },
  {
    field: 'coins',
    headerName: 'Coins',
    flex: 1,
    align: 'right',
  },
  {
    field: 'authorizer',
    headerName: 'Authorized',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    align: 'center',
  },
];
export default function BonusReceived({
  user,
}: UserDetailsProfileTab): JSX.Element {
  return (
    <>
      <CloseableAlert
        severity="warning"
        variant="outlined"
        sx={{
          marginBottom: 3,
        }}
      >
        <AlertTitle>Warning</AlertTitle>
        This section is using mocked data.
      </CloseableAlert>
      <Card>
        <DataGrid
          autoHeight
          rows={MOCK_USER_BONUS_RECEIVED}
          columns={TABLE_HEAD}
        />
      </Card>
    </>
  );
}
