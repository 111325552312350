import { Grid, Stack } from '@mui/material';
import InfoPanel from 'src/components/shared/InfoPanel';
import {
  BIGGEST_BUYER_DATA,
  MARKETPLACE_SALES_DATA,
  MINT_SALES_DATA,
} from 'src/lib/mock/user_sales';

export default function Sales() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <InfoPanel title="Mint Sales" data={MINT_SALES_DATA} />
          <InfoPanel
            title="Biggest Buyer - Spend (Coins)"
            data={BIGGEST_BUYER_DATA}
          />
          <InfoPanel
            title="Biggest Buyer Creator - # NFTs"
            data={BIGGEST_BUYER_DATA}
          />
          <InfoPanel title="Anonymous Buyer" data={BIGGEST_BUYER_DATA} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <InfoPanel title="Marketplace Sales" data={MARKETPLACE_SALES_DATA} />
          <InfoPanel
            title="Biggest Buyer - Spend (Coins)"
            data={BIGGEST_BUYER_DATA}
          />
          <InfoPanel
            title="Biggest Buyer Creator - # NFTs"
            data={BIGGEST_BUYER_DATA}
          />
          <InfoPanel title="Anonymous Buyer" data={BIGGEST_BUYER_DATA} />
        </Stack>
      </Grid>
    </Grid>
  );
}
