export enum BonusTypes {
  daily_bonus = 'daily_bonus',
  mgm_signup = 'mgm_signup',
  mgm_purchase = 'mgm_purchase',
  bonus = 'bonus',
}

export interface IBonus {
  id: number;
  date: string;
  time: string;
  type: BonusTypes;
  coins: number;
  authorizer: string;
  description: string;
}
