import { RegisterForm } from 'src/template/components/sections/auth/register';
import AuthFirebaseSocials from '../../template/components/sections/auth/AuthFirebaseSocial';

export default function Register() {
  return (
    <>
      <AuthFirebaseSocials />
      <RegisterForm />
    </>
  );
}
