import React, { ReactElement } from 'react';
import { User } from 'src/lib/types/users';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';
import AccountDetails from './sections/AccountDetails/AccountDetails';
import BonusReceived from './sections/BonusReceived';
import CreatedCollections from './sections/CreatedCollections';
import PaymentHistory from './sections/PaymentHistory';
import TransactionHistory from './sections/TransactionHistory';
import Wallet from './sections/Wallet';

export type ProfileTabs =
  | 'Account Details'
  | 'Wallet'
  | 'Collections'
  | 'Transactions History'
  | 'Bonus Received'
  | 'Payment History';
interface IProfileTabs {
  value: ProfileTabs;
  icon: ReactElement<IconifyProps>;
  component: (user: User, key: React.Key) => JSX.Element;
}

const PROFILE_TABS: IProfileTabs[] = [
  {
    value: 'Account Details',
    icon: <Iconify icon="ic:round-account-box" width={20} height={20} />,
    component: (user, key) => <AccountDetails user={user} key={key} />,
  },
  {
    value: 'Wallet',
    icon: <Iconify icon="bxs:wallet-alt" width={20} height={20} />,
    component: (user, key) => <Wallet user={user} key={key} />,
  },
  {
    value: 'Collections',
    icon: <Iconify icon="mdi:creation" width={20} height={20} />,
    component: (user, key) => <CreatedCollections user={user} key={key} />,
  },
  {
    value: 'Transactions History',
    icon: <Iconify icon="akar-icons:arrow-repeat" width={20} height={20} />,
    component: (user, key) => <TransactionHistory user={user} key={key} />,
  },
  {
    value: 'Bonus Received',
    icon: <Iconify icon="bx:bx-dollar-circle" width={20} height={20} />,
    component: (user, key) => <BonusReceived user={user} key={key} />,
  },
  {
    value: 'Payment History',
    icon: (
      <Iconify icon="fa6-solid:money-bill-transfer" width={20} height={20} />
    ),
    component: (user, key) => <PaymentHistory user={user} key={key} />,
  },
];

export default PROFILE_TABS;
