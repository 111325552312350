// @mui
import { Button } from '@mui/material';
// components

import useAuth from 'src/template/hooks/useAuth';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

export default function AuthFirebaseSocial() {
  const { loginWithGoogle } = useAuth();

  return (
    <Button
      onClick={loginWithGoogle}
      fullWidth
      size="large"
      variant="outlined"
      color="secondary"
      sx={{
        textTransform: 'none',
      }}
      startIcon={<Iconify icon="eva:google-fill" width={24} height={24} />}
    >
      Login with your Sticky Google Account
    </Button>
  );
}
