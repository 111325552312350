import {
  Avatar,
  Checkbox,
  Link,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Collection } from 'src/lib/types/collection';
import convertUnderscoredText from 'src/lib/utils/convertUnderscoredText';
import { formatDate } from 'src/lib/utils/formatters';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { TableMoreMenu } from 'src/template/components/table';
import useCollectionsTable from './useCollectionsTable';

export default function CollectionTableRow({
  collection,
}: {
  collection: Collection;
}) {
  const [selected, toggleSelected] = useCollectionsTable((state) => [
    state.selected,
    state.toggleSelected,
  ]);

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const getReviewStatusColor = (reviewStatus: string) => {
    switch (reviewStatus) {
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      case 'awaiting_review':
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <TableRow key={collection.uuid} hover>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected.includes(collection.uuid)}
          onClick={() => toggleSelected(collection.uuid)}
        />
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={collection.name}
          src={collection.coverFileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={`/collections/${collection.uuid}`}
          color={collection.name ? 'inherit' : 'text.secondary'}
          underline="hover"
        >
          {collection.name ?? 'empty'}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={collection.active ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {collection.active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell>{collection.nftsCount}</TableCell>
      <TableCell>{collection.user?.name}</TableCell>
      <TableCell>{collection.verified}</TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={getReviewStatusColor(collection.reviewStatus)}
          sx={{ textTransform: 'capitalize' }}
        >
          {convertUnderscoredText(collection.reviewStatus)}
        </Label>
      </TableCell>
      <TableCell align="right">{formatDate(collection.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(collection.createdAt)}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon="eva:trash-2-outline" />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                <Iconify icon="eva:edit-fill" />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
