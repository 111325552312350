import { Navigate, useParams } from 'react-router';

import { Card, CardContent, Grid, Stack } from '@mui/material';
import { fetchNFT } from 'src/lib/services/nft';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Image from 'src/template/components/Image';
import LoadingScreen from 'src/template/components/LoadingScreen';
import NFTAbout from './NFTAbout';

export default function NFT() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/collections" replace />;

  const { data: nft, loading } = fetchNFT(uuid);

  return loading || !nft ? (
    <LoadingScreen />
  ) : (
    <>
      <HeaderBreadcrumbs
        heading={nft?.name || 'Empty Name'}
        links={[
          { name: 'Collections', href: '/collections' },
          {
            name: nft?.collectionUuid || 'empty',
            href: `/collections/${nft?.collectionUuid}`,
          },
          { name: nft?.uuid || 'Empty Name' },
        ]}
      />
      <Stack spacing={3} width="100%">
        <Grid padding={0} container spacing={3} mb={2}>
          <Grid item xs={12} md={7}>
            <Card>
              <CardContent>
                <Image
                  src={nft?.fileUrl || ''}
                  alt={nft?.name || 'Empty Name'}
                  width={300}
                  height={300}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <NFTAbout nft={nft} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
