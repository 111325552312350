import { Container } from '@mui/material';
import { useParams } from 'react-router';
import Sales from 'src/components/modules/Profile/sections/Sales';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';
import useSettings from 'src/template/hooks/useSettings';

export default function UserSalesPage() {
  const { themeStretch } = useSettings();

  const { uuid } = useParams<'uuid'>();

  return (
    <Page title="Users">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Sales"
          links={[
            { name: 'Users', href: '/users' },
            { name: 'Profile', href: `/users/${uuid}` },
            { name: 'Sales' },
          ]}
        />
        <Sales />
      </Container>
    </Page>
  );
}
