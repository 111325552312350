import { User } from 'src/lib/types/users';

interface IAccountInfo {
  label: string;
  valueLabel: string & keyof User;
  isDate?: boolean;
}

const generalAccountInfo: IAccountInfo[] = [
  {
    label: 'A.I. Score',
    valueLabel: 'profileImageAiScore',
  },
  {
    label: 'Language',
    valueLabel: 'lang',
  },
  {
    label: 'Region',
    valueLabel: 'region',
  },
  {
    label: 'Platform',
    valueLabel: 'platform',
  },
  {
    label: 'Version',
    valueLabel: 'version',
  },
  {
    label: 'Created At',
    valueLabel: 'createdAt',
    isDate: true,
  },
  {
    label: 'Last Login',
    valueLabel: 'lastLogin',
    isDate: true,
  },
  {
    label: 'Updated At',
    valueLabel: 'updatedAt',
    isDate: true,
  },
];

export default generalAccountInfo;
