import { Container } from '@mui/material';
import NFT from 'src/components/modules/NFT/NFT';

export default function NFTPage() {
  return (
    <Container>
      <NFT />
    </Container>
  );
}
