/* eslint-disable import/no-extraneous-dependencies */
import { faker } from '@faker-js/faker';
import { BonusTypes, IBonus } from '../types/bonus';

const MOCK_USER_BONUS_RECEIVED: IBonus[] = [
  {
    id: faker.datatype.number(),
    date: faker.date.past().toString(),
    time: faker.date.past().toString(),
    type: BonusTypes.daily_bonus,
    coins: faker.datatype.number(),
    authorizer: faker.name.firstName(),
    description: faker.lorem.sentence(),
  },
  {
    id: faker.datatype.number(),
    date: faker.date.past().toString(),
    time: faker.date.past().toString(),
    type: BonusTypes.mgm_signup,
    coins: faker.datatype.number(),
    authorizer: faker.name.firstName(),
    description: faker.lorem.sentence(),
  },
  {
    id: faker.datatype.number(),
    date: faker.date.past().toString(),
    time: faker.date.past().toString(),
    type: BonusTypes.mgm_purchase,
    coins: faker.datatype.number(),
    authorizer: faker.name.firstName(),
    description: faker.lorem.sentence(),
  },
  {
    id: faker.datatype.number(),
    date: faker.date.past().toString(),
    time: faker.date.past().toString(),
    type: BonusTypes.bonus,
    coins: faker.datatype.number(),
    authorizer: faker.name.firstName(),
    description: faker.lorem.sentence(),
  },
];

export default MOCK_USER_BONUS_RECEIVED;
