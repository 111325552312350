import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import Login from 'src/pages/auth/Login';
import Register from 'src/pages/auth/Register';
import CollectionPage from 'src/pages/CollectionPage';
import Collections from 'src/pages/CollectionsPage';
import Home from 'src/pages/Home';
import NFTPage from 'src/pages/NFTPage';
import ProfilePage from 'src/pages/ProfilePage';
import UserSalesPage from 'src/pages/ProfileSalesPage';
import RegionsPage from 'src/pages/RegionsPage';
import UsersPage from 'src/pages/UsersPage';
import LoadingScreen from 'src/template/components/LoadingScreen';
import AuthGuard from 'src/template/guards/AuthGuard';
import GuestGuard from 'src/template/guards/GuestGuard';
import DashboardLayout from '../template/layouts/dashboard';
import LogoOnlyLayout from '../template/layouts/LogoOnlyLayout';

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NotAuthorized = Loadable(lazy(() => import('../pages/Page401')));

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'users', element: <UsersPage /> },
        { path: 'users/:uuid', element: <ProfilePage /> },
        { path: 'users/:uuid/sales', element: <UserSalesPage /> },
        { path: 'collections', element: <Collections /> },
        { path: 'collections/:uuid', element: <CollectionPage /> },
        { path: 'nfts/:uuid', element: <NFTPage /> },
        { path: 'regions', element: <RegionsPage /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '401', element: <NotAuthorized /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
