import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { reviewCollection } from 'src/lib/services/collections';
import { Collection, CollectionReview } from 'src/lib/types/collection';

type ReviewCollectionModalProps = {
  collection: Collection;
  open: boolean;
  onClose: () => void;
};

export default function ReviewCollectionModal({
  collection,
  open,
  onClose,
}: ReviewCollectionModalProps) {
  const [reviewStatus, setReviewStatus] = useState(collection.reviewStatus);
  const [humanAnalysis, setHumanAnalysis] = useState(collection.humanAnalysis);
  const [aiAnalysis, setAiAnalysis] = useState(collection.aiAnalysis);

  const [reviewMessage, setReviewMessage] = useState('');

  const onApply = async () => {
    const reviewValues = {
      reviewStatus,
      humanAnalysis,
      aiAnalysis,
      reviewMessage,
      revalidate: collection.revalidate,
    } as CollectionReview;

    await reviewCollection(collection.uuid, reviewValues);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Card sx={{ width: '400px' }}>
            <CardHeader title="Review Collection" />
            <CardContent>
              <Stack spacing={2} justifyContent="center" mb={2}>
                <FormControl>
                  <FormLabel>Review Status</FormLabel>
                  <RadioGroup
                    row
                    name="review-status-group"
                    value={reviewStatus}
                    onChange={(e) => setReviewStatus(e.target.value)}
                  >
                    <FormControlLabel
                      value="rejected"
                      control={<Radio />}
                      label="Rejected"
                    />
                    <FormControlLabel
                      value="approved"
                      control={<Radio />}
                      label="Approved"
                    />
                    <FormControlLabel
                      value="reviewing"
                      control={<Radio />}
                      label="Reviewing"
                    />
                    <FormControlLabel
                      value="awaiting_review"
                      control={<Radio />}
                      label="Awaiting Review"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Human Analysis</FormLabel>
                  <RadioGroup
                    row
                    name="human-analysis-group"
                    value={humanAnalysis}
                    onChange={(e) => setHumanAnalysis(e.target.value)}
                  >
                    <FormControlLabel
                      value="everywhere"
                      control={<Radio />}
                      label="Everywhere"
                    />
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Internal"
                    />
                    <FormControlLabel
                      value="public_profile"
                      control={<Radio />}
                      label="Public Profile"
                    />
                    <FormControlLabel
                      value="private_profile"
                      control={<Radio />}
                      label="Private Profile"
                    />
                    <FormControlLabel
                      value="not_set"
                      control={<Radio />}
                      label="Not Set"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>A.I. Analysis</FormLabel>
                  <RadioGroup
                    row
                    name="ai-analysis-group"
                    value={aiAnalysis}
                    onChange={(e) => setAiAnalysis(e.target.value)}
                  >
                    <FormControlLabel
                      value="approved"
                      control={<Radio />}
                      label="Approved"
                    />
                    <FormControlLabel
                      value="review"
                      control={<Radio />}
                      label="Review"
                    />
                    <FormControlLabel
                      value="denied"
                      control={<Radio />}
                      label="Denied"
                    />
                    <FormControlLabel
                      value="not_set"
                      control={<Radio />}
                      label="Not Set"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  label="Review Message"
                  multiline
                  value={reviewMessage}
                  onChange={(e) => setReviewMessage(e.target.value)}
                />
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Button color="error" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onApply}
                  disabled={reviewMessage === ''}
                >
                  Apply
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Fade>
    </Modal>
  );
}
