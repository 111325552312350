import { Navigate, useParams } from 'react-router';
import { fetchUser } from 'src/lib/services/users';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import useTabs from 'src/template/hooks/useTabs';
import ProfileHeader from './ProfileHeader';
import PROFILE_TABS from './profileHeaderTabs';

export default function Profile(): JSX.Element {
  const { currentTab, onChangeTab } = useTabs('Account Details');
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/users" replace />;

  const { data: user, loading } = fetchUser(uuid);

  return (
    <>
      {loading && <LoadingScreen />}
      {user && (
        <>
          <HeaderBreadcrumbs
            heading={user.name}
            links={[{ name: 'Users', href: '/users' }, { name: user.uuid }]}
          />
          <ProfileHeader
            onChangeTab={onChangeTab}
            currentTab={currentTab}
            user={user}
          />
          {PROFILE_TABS.map(
            (tab, index) =>
              tab.value === currentTab && tab.component(user, index)
          )}
        </>
      )}
    </>
  );
}
