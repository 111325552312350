import createUseTable from '../../../../lib/utils/createUseTable';

export type OrderByOptions = 'name' | 'createdAt' | 'updatedAt';
export type SearchForOptions = 'name' | 'description' | 'creator';

const useCollectionsTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'name',
  order: 'asc',
  orderBy: 'createdAt',
  query: '',
  page: 0,
  size: 10,
});

export default useCollectionsTable;
