// components

import { CollectionsRounded, PublicRounded } from '@mui/icons-material';
import SvgIconStyle from 'src/template/components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  collections: <CollectionsRounded />,
  regions: <PublicRounded />,
};

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        path: '/users',
        icon: ICONS.user,
      },
      {
        title: 'collections',
        path: '/collections',
        icon: ICONS.collections,
      },
      {
        title: 'regions',
        path: '/regions',
        icon: ICONS.regions,
      },
    ],
  },
];

export default navConfig;
