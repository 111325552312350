/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { DataGrid, getGridDateOperators, GridColumns } from '@mui/x-data-grid';
import dateRangeOperator from 'src/lib/custom/dateRangeOperator';
import { Payment, PaymentStatuses } from 'src/lib/types/payment';
import { UserDetailsProfileTab } from 'src/lib/types/userDetails_tabs';
import {
  formatOptionalTableData,
  formatOptionalTableDataDate,
} from 'src/lib/utils/formatters';
import Label from 'src/template/components/Label';

const TABLE_HEAD: GridColumns<Payment> = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'date',
    flex: 1,
    align: 'left',
    valueGetter: formatOptionalTableDataDate,
    filterOperators: [...dateRangeOperator, ...getGridDateOperators()],
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    align: 'left',
    type: 'singleSelect',
    valueOptions: Object.values(PaymentStatuses),
  },
  {
    field: 'scheduleAt',
    headerName: 'Scheduled At',
    type: 'date',
    flex: 1,
    align: 'left',
    valueGetter: formatOptionalTableDataDate,
    filterOperators: [...dateRangeOperator, ...getGridDateOperators()],
  },
  {
    field: 'excutedAt',
    headerName: 'Executed At',
    type: 'date',
    flex: 1,
    align: 'left',
    valueGetter: formatOptionalTableDataDate,
    filterOperators: [...dateRangeOperator, ...getGridDateOperators()],
  },
  {
    field: 'amount',
    headerName: 'Coins',
    flex: 1,
    align: 'left',
  },
  {
    field: 'currency',
    headerName: 'Currency',
    flex: 1,
    align: 'left',
  },
  {
    field: 'value',
    headerName: '$',
    flex: 1,
    align: 'center',
    headerAlign: 'left',
    valueGetter: formatOptionalTableData,
  },
];

export default function PaymentHistory({
  user,
}: UserDetailsProfileTab): JSX.Element {
  const { paymentRequests: paymentHistory } = user;

  return (
    <Stack spacing={3}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={5}>
            <Typography>
              Requested ($): <UnavailableYet />
            </Typography>
            <Typography>
              Scheduled ($): <UnavailableYet />
            </Typography>
            <Typography>
              Executed ($): <UnavailableYet />
            </Typography>
            <Typography>
              Cancelled ($): <UnavailableYet />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <DataGrid
          columns={TABLE_HEAD}
          rows={paymentHistory}
          getRowId={(payment) => payment?.uuid}
          autoHeight
        />
      </Card>
    </Stack>
  );
}

function UnavailableYet() {
  return (
    <Label ml={4} color="warning" fontWeight="bold">
      Unavailable Yet
    </Label>
  );
}
