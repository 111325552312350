import {
  Avatar,
  Box,
  Card,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import { User } from 'src/lib/types/users';
import cssStyles from 'src/template/utils/cssStyles';
import PROFILE_TABS from './profileHeaderTabs';

interface UserDetailsHeaderProps {
  user: User;
  onChangeTab: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => void;
  currentTab: string;
}

const RootStyle = styled('div')(({ theme }) => ({
  '&:before': {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

export default function ProfileHeader({
  user,
  onChangeTab,
  currentTab,
}: UserDetailsHeaderProps): JSX.Element {
  return (
    <Card
      sx={{
        mb: 3,
        height: 280,
        position: 'relative',
      }}
    >
      {/* <Image src={user} /> */}
      <RootStyle>
        <InfoStyle>
          <Avatar
            src={user.profileImage}
            alt={`${user.name} profile image`}
            sx={{
              mx: 'auto',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'common.white',
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
          />
          <Box
            sx={{
              ml: { md: 3 },
              mt: { xs: 1, md: 0 },
              color: 'common.white',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography variant="h4">{user?.name}</Typography>
            <Typography sx={{ opacity: 0.72 }}>{user?.slug}</Typography>
          </Box>
        </InfoStyle>
      </RootStyle>
      <TabsWrapperStyle>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.value}
            />
          ))}
        </Tabs>
      </TabsWrapperStyle>
    </Card>
  );
}
