/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { DataGrid, getGridDateOperators, GridColumns } from '@mui/x-data-grid';
import dateRangeOperator from 'src/lib/custom/dateRangeOperator';
import fetchMoves from 'src/lib/services/transactions';
import { Transaction } from 'src/lib/types/transaction';
import { UserDetailsProfileTab } from 'src/lib/types/userDetails_tabs';
import { formatOptionalTableDataDate } from 'src/lib/utils/formatters';
import Label from 'src/template/components/Label';

interface TransactionHistoryProps extends UserDetailsProfileTab {}

const TABLE_HEAD: GridColumns<Transaction> = [
  {
    field: 'createdAt',
    headerName: 'Date',
    type: 'date',
    flex: 1,
    align: 'left',
    valueGetter: formatOptionalTableDataDate,
    filterOperators: [...dateRangeOperator, ...getGridDateOperators()],
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    align: 'left',
  },
  {
    field: 'amount',
    headerName: 'Coins',
    flex: 1,
    align: 'left',
  },
  {
    field: 'mint',
    headerName: 'Mint Passes',
    flex: 1,
    align: 'left',
  },
];

export default function TransactionHistory({
  user,
}: TransactionHistoryProps): JSX.Element {
  const { moves, loading } = fetchMoves(user.uuid);

  const movesData = moves?.map((move) => ({
    uuid: move.uuid,
    createdAt: move.createdAt,
    type: move.type,
    amount: move.amount,
    mint: move.mint,
  }));

  return (
    <Stack spacing={3}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={5}>
            <Typography>
              Sales: <UnavailableYet />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <DataGrid
          columns={TABLE_HEAD}
          rows={movesData ?? []}
          getRowId={(row) => row?.uuid}
          autoHeight
          loading={loading}
        />
      </Card>
    </Stack>
  );
}

function UnavailableYet() {
  return (
    <Label ml={4} color="warning" fontWeight="bold">
      Unavailable Yet
    </Label>
  );
}
