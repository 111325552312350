// routes
import { SnackbarProvider } from 'notistack';
import { SWRConfig } from 'swr';
import Router from './routes';
// theme
import ThemeProvider from './template/theme';
// components
import fetcher from './lib/services/api/fetcher';
import MotionLazyContainer from './template/components/animate/MotionLazyContainer';
import { ProgressBarStyle } from './template/components/ProgressBar';
import ScrollToTop from './template/components/ScrollToTop';
import ThemeSettings from './template/settings';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <SnackbarProvider maxSnack={3}>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </SnackbarProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </SWRConfig>
  );
}
