import { Box } from '@mui/material';
import React from 'react';

export default function Center({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        columnFill: 'auto',
      }}
    >
      {children}
    </Box>
  );
}
