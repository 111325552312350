import create from 'zustand';
import { BracketFilterParams } from '../types/bracketFilterParams';

interface IUseTable<OrderByOptions = any, SearchForOptions = any> {
  filter: BracketFilterParams<OrderByOptions, SearchForOptions>;

  selected: string[];
  dense: boolean;

  toggleSelected: (uuid: string) => void;
  toggleSelectAll: (selected: string[]) => void;
  setQuery: (query: string) => void;
  onPageChange: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDenseChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchFor: (searchFor: SearchForOptions) => void;
  onSort: (property: string, ignoredProperties?: string[]) => void;
}

const createUseTable = <
  OrderByOptions extends string,
  SearchForOptions extends string
>(
  initialFilterState: BracketFilterParams<OrderByOptions, SearchForOptions>
) =>
  create<IUseTable<OrderByOptions, SearchForOptions>>((set) => ({
    filter: initialFilterState,

    selected: [],
    dense: false,

    toggleSelected: (uuid) =>
      set((state) => ({
        selected: state.selected.includes(uuid)
          ? state.selected.filter((id) => id !== uuid)
          : [...state.selected, uuid],
      })),
    toggleSelectAll: (ids: string[]) => {
      set((state) => ({
        selected: state.selected.length > 0 ? [] : ids,
      }));
    },
    setQuery: (query) =>
      set((state) => ({
        filter: {
          ...state.filter,
          query,
          page: 0,
        },
      })),
    onPageChange: (e, page) =>
      set((state) => ({
        filter: {
          ...state.filter,
          page,
        },
      })),
    onRowsPerPageChange: (e) =>
      set((state) => ({
        filter: {
          ...state.filter,
          size: parseInt(e.target.value, 10),
          page: 0,
        },
      })),
    onDenseChange: (e) => set({ dense: e.target.checked }),
    setSearchFor: (searchFor: SearchForOptions) =>
      set((state) => ({
        filter: {
          ...state.filter,
          searchFor,
        },
      })),
    onSort: (property, ignoredProperties) => {
      if (ignoredProperties && ignoredProperties.includes(property)) return;

      set((state) => ({
        filter: {
          ...state.filter,
          order:
            state.filter.orderBy === property
              ? state.filter.order === 'asc'
                ? 'desc'
                : 'asc'
              : 'asc',
          orderBy: property as OrderByOptions,
        },
      }));
    },
  }));

export default createUseTable;
