import { Visibility } from '@mui/icons-material';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { User } from 'src/lib/types/users';
import Image from 'src/template/components/Image';
import Label from 'src/template/components/Label';
import useTable from 'src/template/hooks/useTable';

const TABLE_HEAD: GridColumns = [
  {
    field: 'cover',
    headerName: 'Cover',
    align: 'center',
    flex: 1,
    renderCell: (params) => (
      <Image src={params.value} alt={params.row.name} ratio="1/1" />
    ),
  },
  { field: 'name', headerName: 'Collection Name', align: 'left', flex: 1 },
  { field: 'status', headerName: 'Review', align: 'left', flex: 1 },
  { field: 'ha', headerName: 'H.A.', align: 'left', flex: 1 },
  { field: 'sales', headerName: 'Sales', align: 'right', flex: 1 },
  { field: 'nftsSold', headerName: 'NFTs Sold', align: 'right', flex: 1 },
  {
    field: 'id',
    headerName: 'View',
    align: 'right',
    flex: 0,
    renderCell: (params) => (
      <Link to={`/collections/${params.value}`}>
        <IconButton>
          <Visibility />
        </IconButton>
      </Link>
    ),
  },
];

interface CreatedNFTsPropsProps {
  user: User;
}

export default function CreatedCollections({
  user,
}: CreatedNFTsPropsProps): JSX.Element {
  const { rowsPerPage, onChangeRowsPerPage } = useTable();

  const collectionsData = user.collections.map((collection) => ({
    id: collection.uuid,
    cover: collection.coverFileUrl,
    name: collection.name,
    status: collection.reviewStatus,
    ha: collection.humanAnalysis,
    sales: collection.salesAmount,
    nftsSold: collection.salesCount,
  }));

  return (
    <Stack spacing={3}>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={5}>
            <Typography noWrap>
              Sales: <UnavailableYet />
            </Typography>
            <Typography>
              NFTs Sold: <UnavailableYet />
            </Typography>
            <Typography>
              Unique Buyers: <UnavailableYet />
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <DataGrid
          rows={collectionsData}
          columns={TABLE_HEAD}
          autoHeight
          onPageSizeChange={(size) => onChangeRowsPerPage(size)}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </Stack>
  );
}

function UnavailableYet() {
  return (
    <Label ml={4} color="warning" fontWeight="bold">
      Unavailable Yet
    </Label>
  );
}
