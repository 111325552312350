import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchUsers } from 'src/lib/services/users';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import UsersTable from './UsersTable/UsersTable';
import UsersTableFooter from './UsersTable/UsersTableFooter';
import useUsersTable, { SearchForOptions } from './UsersTable/useUsersTable';

const SEARCH_OPTIONS: SearchForOptions[] = [
  'name',
  'email',
  'authId',
  'lang',
  'region',
  'platform',
  'slug',
];

export default function Users() {
  const { query, page, order, orderBy, searchFor, size } = useUsersTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useUsersTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const {
    data: users,
    loading,
    total,
  } = fetchUsers({
    query: useDebounce(query, 300),
    page,
    order,
    orderBy,
    searchFor,
    size,
  });

  return (
    <>
      <HeaderBreadcrumbs heading="Users" links={[{ name: 'Users' }]} />
      <Card>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search user..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <UsersTable loading={loading} data={users} />
        </Scrollbar>

        <UsersTableFooter total={total || 0} page={page} rowsPerPage={size} />
      </Card>
    </>
  );
}
