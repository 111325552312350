import { Card, CardContent, Stack, Typography } from '@mui/material';

type InfoPanelData = {
  [Key: string]: number | string;
};

type InfoPanelProps = {
  title: string;
  data: InfoPanelData;
  balance?: number;
};

export default function InfoPanel({ title, data, balance }: InfoPanelProps) {
  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>

        <Stack spacing={2}>
          <Typography variant="h3">{balance}</Typography>

          {Object.keys(data).map((key) => (
            <Stack direction="row" justifyContent="space-between" key={key}>
              <Typography variant="body2" color="text.secondary">
                {key}
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {data[key]}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
