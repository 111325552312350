import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Alert, { AlertProps } from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

interface CloseableAlertProps extends AlertProps {
  children: React.ReactNode;
}

export default function CloseableAlert({
  children,
  ...alertProps
}: CloseableAlertProps) {
  const [open, setOpen] = useState(true);

  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  }, [closing]);

  return open ? (
    <Collapse in={!closing}>
      <Alert
        {...alertProps}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setClosing(true);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {children}
      </Alert>
    </Collapse>
  ) : null;
}
