import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Modal,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { uploadRegionsCSV } from 'src/lib/services/regions';
import csvToArray from 'src/lib/utils/csvToArray';
import Label from 'src/template/components/Label';
import { UploadSingleFile } from 'src/template/components/upload';

type UploadRegionCsvModalProps = {
  open: boolean;
  onClose: () => void;
};

export default function UploadRegionCsvModal({
  open,
  onClose,
}: UploadRegionCsvModalProps) {
  const [csv, setCSV] = useState<any>(null);

  const { enqueueSnackbar: notify } = useSnackbar();

  const apply = async () => {
    const csvArray = await csvToArray(csv[0], ',');
    const res = await uploadRegionsCSV(csvArray);

    if (!res.error) {
      notify(`Created ${res.created.totalCount} regions`, {
        variant: 'success',
      });
      notify(`Updated ${res.updated.totalCount} regions`, {
        variant: 'success',
      });
    } else {
      notify('Failed to upload regions', { variant: 'error' });
    }

    setCSV(null);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Card sx={{ width: '600px' }}>
            <CardHeader title="Upload CSV File" />
            <CardContent>
              <UploadSingleFile file={csv} onDrop={setCSV} />
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Button
                  color="error"
                  onClick={() => {
                    setCSV(null);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                {csv &&
                  (csv[0].type === 'text/csv' ? (
                    <Label color="success">{csv[0].name}</Label>
                  ) : (
                    <Label color="error">{csv[0].name} is not a CSV file</Label>
                  ))}
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={apply}
                  disabled={!csv || csv[0].type !== 'text/csv'}
                >
                  Apply
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Fade>
    </Modal>
  );
}
