import { Visibility } from '@mui/icons-material';
import {
  Avatar,
  Checkbox,
  Link,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { User } from 'src/lib/types/users';
import { formatDate } from 'src/lib/utils/formatters';
import Label from 'src/template/components/Label';
import { TableMoreMenu } from 'src/template/components/table';
import useUsersTable from './useUsersTable';

export default function UserTableRow({ user }: { user: User }) {
  const navigate = useNavigate();

  const [selected, toggleSelected] = useUsersTable((state) => [
    state.selected,
    state.toggleSelected,
  ]);

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow key={user.uuid} hover>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected.includes(user.uuid)}
          onClick={() => toggleSelected(user.uuid)}
        />
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={user.name} src={user.profileImage} sx={{ mr: 2 }} />
        <Link
          component={RouterLink}
          to={`/users/${user.authId}`}
          color={user.name ? 'inherit' : 'text.secondary'}
          underline="hover"
        >
          {user.name ?? 'empty'}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={user.active ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {user.active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.authId}</TableCell>
      <TableCell>{user.slug}</TableCell>
      <TableCell>{formatDate(user.lastLogin)}</TableCell>
      <TableCell align="right">{formatDate(user.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(user.createdAt)}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                navigate(`/users/${user.uuid}`);
              }}
            >
              <Visibility />
              More Details
            </MenuItem>
          }
        />
      </TableCell>
    </TableRow>
  );
}
