import { Grid, Stack } from '@mui/material';
import InfoPanel from 'src/components/shared/InfoPanel';
import { NFT_BALANCE_DATA } from 'src/lib/mock/user_wallet';
import { User } from 'src/lib/types/users';

export default function Wallet({ user }: { user: User }) {
  const { wallet } = user;

  const COINS_BALANCE_DATA = {
    'LT Sales': wallet.lifetimeSales,
    'LT Coins': wallet.lifetimeCredit,
    'LT Credit': wallet.lifetimeCredit,
    'LT Spending': wallet.lifetimeSpending,
    'LT Payments': wallet.lifetimePayments,
  };

  const MINT_BALANCE_DATA = {
    'LT Mint': wallet.lifetimeMint,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Stack spacing={3}>
          <InfoPanel
            balance={wallet.balance}
            title="Coins Balance"
            data={COINS_BALANCE_DATA}
          />
          <InfoPanel
            balance={wallet.mint}
            title="Mint Pass Balance"
            data={MINT_BALANCE_DATA}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <InfoPanel title="NFTS" data={NFT_BALANCE_DATA} />
      </Grid>
    </Grid>
  );
}
