import { RateReviewRounded } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Card, Divider, Grid, Stack, Tab } from '@mui/material';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { fetchCollection } from 'src/lib/services/collections';
import { fetchNFTsFromCollection } from 'src/lib/services/nft';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Image from 'src/template/components/Image';
import LoadingScreen from 'src/template/components/LoadingScreen';
import NFTsGrid from '../../NFT/NFTsGrid';
import CollectionCreatorCard from '../CollectionCreatorCard';
import ReviewCollectionModal from '../ReviewCollectionModal';
import CollectionAbout from './CollectionAbout';
import CollectionDescription from './CollectionDescription';
import CollectionSocialMedia from './CollectionSocialMedia';

export default function Collection() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/collections" replace />;

  const { data: collection } = fetchCollection(uuid);
  const { data: nfts } = fetchNFTsFromCollection(uuid);

  const [showReviewCollectionModal, setShowReviewCollectionModal] =
    useState(false);

  const handleOpen = () => setShowReviewCollectionModal(true);
  const handleClose = () => setShowReviewCollectionModal(false);

  return !collection ? (
    <LoadingScreen />
  ) : (
    <>
      <HeaderBreadcrumbs
        heading={collection?.name || 'Empty Name'}
        links={[
          { name: 'Collections', href: '/collections' },
          { name: collection?.uuid || 'Empty Name' },
        ]}
        action={
          <Button
            variant="contained"
            color="info"
            size="large"
            onClick={handleOpen}
            startIcon={<RateReviewRounded />}
          >
            Review
          </Button>
        }
      />
      <ReviewCollectionModal
        collection={collection}
        open={showReviewCollectionModal}
        onClose={handleClose}
      />
      <Stack spacing={3}>
        <Grid padding={0} container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={2}>
              <Card>
                <Image
                  src={collection?.coverFileUrl}
                  alt="Collection Cover Image"
                  ratio="1/1"
                />
              </Card>
              {collection?.user && (
                <CollectionCreatorCard {...collection.user} />
              )}
              <CollectionAbout collection={collection} />
              <CollectionDescription description={collection.description} />
              <CollectionSocialMedia />
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <TabContext value="nfts">
              <Stack spacing={3}>
                <Box>
                  <TabList>
                    <Tab value="nfts" label="NFTs" />
                  </TabList>
                  <Divider />
                </Box>
                <TabPanel value="nfts">
                  <NFTsGrid data={nfts} />
                </TabPanel>
              </Stack>
            </TabContext>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
