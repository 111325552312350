import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { LTVRegion } from 'src/lib/types/ltvRegion';
import TableHeadCustom, {
  IHeadLabel,
} from 'src/template/components/table/TableHeadCustom';
import RegionsTableRow from './RegionsTableRow';
import useRegionsTable from './useRegionsTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'code', label: 'Code' },
  { id: 'name', label: 'Name' },
  { id: 'productFullId', label: 'Product' },
  { id: 'iosStickyValue', label: 'iOS', align: 'right' },
  { id: 'actions', label: 'Actions', align: 'right' },
];

export default function RegionsTable({
  data,
  loading,
}: {
  data: LTVRegion[] | undefined;
  loading: boolean;
}) {
  const [selected, toggleSelectAll] = useRegionsTable((state) => [
    state.selected,
    state.toggleSelectAll,
  ]);

  const [order, orderBy] = useRegionsTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useRegionsTable((state) => state.onSort);

  const dense = useRegionsTable((state) => state.dense);

  const onSelectAll = () => {
    toggleSelectAll(
      data?.map(
        (region) =>
          region.code +
          region.name +
          region.productFullId +
          region.iosStickyValue
      ) || []
    );
  };

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table
        size={dense ? 'small' : 'medium'}
        aria-labelledby="tableTitle"
        aria-label="enhanced table"
      >
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          rowCount={data?.length || 0}
          numSelected={selected.length}
          headLabel={TABLE_HEAD}
          onSort={onSort}
          onSelectAllRows={onSelectAll}
        />
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : data?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length}>
                <Center>No data</Center>
              </TableCell>
            </TableRow>
          ) : (
            data?.map((region) => (
              <RegionsTableRow key={region.uuid} region={region} />
            )) || []
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
