import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NFT } from 'src/lib/types/nft';
import Image from 'src/template/components/Image';

type NFTCardProps = {
  data: NFT;
};

export default function NFTCard({ data }: NFTCardProps) {
  return (
    <Card>
      <Link component={RouterLink} to={`/nfts/${data.uuid}`} underline="none">
        <CardActionArea>
          <Image src={data.fileUrl} ratio="1/1" />
          <CardContent sx={{ padding: 2 }}>
            {data.name ? (
              <Typography fontWeight="bold">{data.name}</Typography>
            ) : (
              <Typography color="text.secondary" fontWeight="bold">
                Empty Name
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
