type SocialMedias =
  | 'facebook'
  | 'twitter'
  | 'instagram'
  | 'discord'
  | 'youtube'
  | 'tiktok'
  | 'website';

interface ISocialMedia {
  name: SocialMedias;
  label: string;
  icon: string;
}

const userSocialMedias: ISocialMedia[] = [
  {
    name: 'facebook',
    label: 'Facebook',
    icon: 'ic:round-facebook',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    icon: 'ant-design:twitter-outlined',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    icon: 'ant-design:instagram-filled',
  },
  {
    name: 'discord',
    label: 'Discord',
    icon: 'ic:round-discord',
  },
  {
    name: 'youtube',
    label: 'YouTube',
    icon: 'ant-design:youtube-filled',
  },
  {
    name: 'tiktok',
    label: 'TikTok',
    icon: 'ic:round-tiktok',
  },
  {
    name: 'website',
    label: 'Website',
    icon: 'ic:round-public',
  },
];

export default userSocialMedias;
