export const COINS_BALANCE_DATA = {
  'LT Sales': 260000,
  'LT Royalties': 12479,
  'LT Coin Purchases': 80000,
  'LT Coin Bonuses': 25000,
  'LT Invite Bonus': 0,
  'LT Spending': -211277,
  'LT Payouts': 0,
  'LT Payouts Pending': -100000,
};

export const MINT_BALANCE_DATA = {
  'LT Mint Sales': 260000,
  'LT Royalties': 12479,
  'LT Coin Purchases': 80000,
  'LT Coin Bonuses': 25000,
  'LT Invite Bonus': 0,
  'LT Spending': -211277,
  'LT Payouts': 0,
  'LT Payouts Pending': -100000,
};

export const NFT_BALANCE_DATA = {
  'NFT Collections Minted': '-',
  'NFT Collections Collected': '-',
  'NFTs Created': '-',
  'NFTs Collected': '-',
};
