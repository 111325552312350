import { BracketFilterParams } from '../types/bracketFilterParams';

export default function generateSearchRequest({
  orderBy,
  order,
  searchFor,
  query,
  page,
  size,
}: BracketFilterParams<string, string>) {
  if (query === '') {
    return `?order[${orderBy}]=${order}&page=${page}&size=${size}`;
  }

  return `?order[${orderBy}]=${order}&page=${page}&size=${size}&filter[${searchFor}][like]=%${query}%`;
}
