import { Alert, Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function Home() {
  return (
    <Page title="Sticky Admin">
      <Container>
        <HeaderBreadcrumbs heading="Sticky Admin" links={[{ name: 'Home' }]} />
        <Alert severity="info" variant="outlined">
          Please, refer to the sidebar to test the main features currently
          available
        </Alert>
      </Container>
    </Page>
  );
}
