import createUseTable from 'src/lib/utils/createUseTable';

export type OrderByOptions =
  | 'name'
  | 'status'
  | 'email'
  | 'authId'
  | 'slug'
  | 'login'
  | 'updatedAt';

export type SearchForOptions =
  | 'name'
  | 'email'
  | 'authId'
  | 'lang'
  | 'region'
  | 'platform'
  | 'slug';

const useUsersTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'name',
  order: 'asc',
  orderBy: 'name',
  query: '',
  page: 0,
  size: 10,
});

export default useUsersTable;
