import useSWR from 'swr';
import { Fetch } from '../types/fetch';
import { NFT } from '../types/nft';
import { Response } from '../types/response';

/**
 * Fetches a NFT
 * @param {string} uuid - The NFT's uuid
 * @returns {Fetch<NFT>} returns a NFT that follows swr's fetch format
 */
export const fetchNFT = (uuid: string): Fetch<NFT> => {
  const { data, error } = useSWR<Response<NFT>, Error>(`nft/${uuid}`);

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Fetches NFTs from a collection
 * @param {string} collection - The collection's uuid
 * @returns {Fetch<NFT[]>} returns the NFTs from the collection that follows swr's fetch format
 */
export const fetchNFTsFromCollection = (collection: string): Fetch<NFT[]> => {
  const { data, error } = useSWR<Response<NFT[]>, Error>(
    `admin/nfts/?filter[collectionUuid][like]=${collection}`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};
