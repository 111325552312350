import useSWR, { mutate } from 'swr';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Collection, CollectionReview } from '../types/collection';
import { Fetch } from '../types/fetch';
import { NFT } from '../types/nft';
import { Response } from '../types/response';
import { User } from '../types/users';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

/**
 * Fetches a list of collections
 * @param {BracketFilterParams} filter - Object that contains the bracket filter parameters
 * @return {Fetch<Collection[]>} returns a list of collections that follows swr's fetch format plus the total count of collections
 */
export const fetchCollections = (
  filter: BracketFilterParams,
  reviewStatus = 'all'
): Fetch<Collection[]> => {
  const statusFilter =
    reviewStatus === 'all' ? '' : `&filter[reviewStatus]=${reviewStatus}`;

  const { data, error } = useSWR<Response<Collection[]>, Error>(
    `admin/collections/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Fetches a collection
 * @param {string} uuid - The collection's uuid
 * @returns {Fetch<Collection>} returns a collection that follows swr's fetch format
 */
export const fetchCollection = (uuid: string): Fetch<Collection<User, NFT>> => {
  const { data, error } = useSWR<Collection, Error>(
    `admin/collections/${uuid}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Review a Collection
 * @param {string} uuid - The collection's uuid
 * @param {CollectionReview} reviewValues - The review values
 */
export const reviewCollection = async (
  uuid: string,
  reviewValues: CollectionReview
) => {
  const api = await getAPIClient();

  await api.patch(`admin/collections/${uuid}/review`, reviewValues);
  mutate(`admin/collections/${uuid}`);
};
