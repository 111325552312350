const csvToArray = async (csv: File, delimiter: string) => {
  const csvStr = await csv.text();

  const headers = csvStr.slice(0, csvStr.indexOf('\n')).split(delimiter);
  const rows = csvStr.slice(csvStr.indexOf('\n') + 1).split('\n');

  const arr = rows.map((row) => {
    const values = row.split(delimiter);
    const rowEl = headers.reduce((object: any, header, index) => {
      const newEl = { ...object };
      newEl[header] = values[index];
      return newEl;
    }, {});
    return rowEl;
  });

  return arr.filter((row) => row.code !== '');
};

export default csvToArray;
