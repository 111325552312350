export const MINT_SALES_DATA = {
  'LT Mint Sales': 230000,
  Buyers: 150,
  Collections: 3,
  NFTs: 590,
};

export const MARKETPLACE_SALES_DATA = {
  'LT MktPlace Sales': 30000,
  'Buyers / Wallets': 3,
  'Avg / Wallet': 10000,
  NFTs: 5,
};

export const BIGGEST_BUYER_DATA = {
  'Biggest Buyer': 'Comprador',
  'Spend (Coins)': 100000,
  'NFTs Puchased': 2,
};
