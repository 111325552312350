import { Box, InputAdornment, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { NFT } from 'src/lib/types/nft';
import Iconify from 'src/template/components/Iconify';
import NFTCard from './NFTCard';
import NFTFilter from './NFTFilter';

type NFTsGridProps = {
  data: NFT[] | undefined;
};

export default function NFTsGrid({ data }: NFTsGridProps) {
  const [query, setQuery] = useState('');

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3} justifyContent="space-between">
        <TextField
          value={query}
          size="small"
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search nft..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <NFTFilter />
      </Stack>
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
        }}
      >
        {data?.map((nft) => (
          <NFTCard key={nft.uuid} data={nft} />
        ))}
      </Box>
    </Stack>
  );
}
