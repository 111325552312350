import { DeleteRounded, Edit } from '@mui/icons-material';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import { LTVRegion } from 'src/lib/types/ltvRegion';
import DeleteRegionModal from '../DeleteRegionModal';
import EditOrCreateRegionModal from '../EditOrCreateRegionModal';
import useRegionsTable from './useRegionsTable';

export default function RegionsTableRow({ region }: { region: LTVRegion }) {
  const [selected, toggleSelected] = useRegionsTable((state) => [
    state.selected,
    state.toggleSelected,
  ]);

  const selectId =
    region.code + region.name + region.productFullId + region.iosStickyValue;

  const [showEditRegion, setShowEditRegion] = useState({
    show: false,
    region: undefined as LTVRegion | undefined,
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    region: undefined as LTVRegion | undefined,
  });

  return (
    <>
      <TableRow key={region.code} hover>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected.includes(selectId)}
            onClick={() => toggleSelected(selectId)}
          />
        </TableCell>
        <TableCell>{region.code}</TableCell>
        <TableCell>{region.name}</TableCell>
        <TableCell>{region.productFullId}</TableCell>
        <TableCell align="right">{region.iosStickyValue}</TableCell>
        <TableCell align="right">
          <IconButton
            onClick={() =>
              setShowEditRegion({
                show: true,
                region,
              })
            }
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() =>
              setShowDeleteConfirmation({
                show: true,
                region,
              })
            }
          >
            <DeleteRounded />
          </IconButton>
        </TableCell>
      </TableRow>
      <EditOrCreateRegionModal
        open={showEditRegion.show}
        onClose={() =>
          setShowEditRegion({
            show: false,
            region: undefined,
          })
        }
        region={showEditRegion.region}
      />
      <DeleteRegionModal
        open={showDeleteConfirmation.show}
        onClose={() =>
          setShowDeleteConfirmation({
            show: false,
            region: undefined,
          })
        }
        region={showDeleteConfirmation.region!}
      />
    </>
  );
}
