import {
  AlertTitle,
  Box,
  Card,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import { User } from 'src/lib/types/users';
import { formatDate } from 'src/lib/utils/formatters';
import Iconify from 'src/template/components/Iconify';
import generalAccountInfo from './accountInfo';
import userSocialMedias from './socialMedia';

const gridStyle = {
  display: 'grid',
  rowGap: 3,
  columnGap: 2,
  gridTemplateColumns: {
    xs: 'repeat(1, 1fr)',
    sm: 'repeat(2, 1fr)',
  },
};

interface UserDetailsProfileProps {
  user: User;
}

export default function AccountDetails({
  user,
}: UserDetailsProfileProps): JSX.Element {
  return (
    <>
      <CloseableAlert
        severity="warning"
        variant="outlined"
        sx={{
          marginBottom: 3,
        }}
      >
        <AlertTitle>Warning</AlertTitle>
        Birthday is not available yet.
      </CloseableAlert>
      <Grid padding={0} container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={gridStyle}>
              <TextField value={user.name || ''} name="name" label="Name" />
              <TextField value={user.slug || ''} name="slug" label="Slug" />
              <Box sx={{ gridColumn: { xs: 'span 1', sm: 'span 2' } }}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={user.bio || ''}
                  name="bio"
                  label="Bio"
                />
              </Box>
            </Box>
          </Card>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={gridStyle}>
              <TextField
                value={user.paymentInfo?.address || ''}
                name="address"
                label="Address"
              />
              <TextField
                value={user.paymentInfo?.city || ''}
                name="city"
                label="City"
              />
              <TextField
                value={user.paymentInfo?.address || ''}
                name="state"
                label="State/Region"
              />
              <TextField
                value={user.paymentInfo?.country || ''}
                name="country"
                label="Country"
              />
              <TextField
                value={user.paymentInfo?.postalCode || ''}
                name="zipCode"
                label="Zip/Code"
              />
            </Box>
          </Card>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={gridStyle}>
              <TextField
                name="birth_date"
                label="Birthday"
                type="date"
                value=""
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                value={user.email}
                name="email"
                label="Email Address"
              />
              <TextField
                value={user.paymentInfo?.phoneNumber || ''}
                name="phone"
                label="Cell Phone"
              />
            </Box>
          </Card>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={gridStyle}>
              {userSocialMedias.map((socialMedia) => (
                <TextField
                  key={socialMedia.name}
                  name={socialMedia.name}
                  label={socialMedia.label}
                  value={user.socialMedia?.[socialMedia.name] || ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={socialMedia.icon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link target="_blank" rel="noopener noreferrer">
                          <Iconify icon="bx:bx-link-external" />
                        </Link>
                      </InputAdornment>
                    ),
                  }}
                />
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box>
              <Typography
                component={Link}
                variant="body1"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Iconify sx={{ mr: 1 }} icon="akar-icons:link-out" />
                Selfie
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography
                component={Link}
                variant="body1"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Iconify sx={{ mr: 1 }} icon="akar-icons:link-out" />
                Document
              </Typography>
            </Box>
            <Box sx={{ minWidth: 0 }}>
              <Typography
                component={Link}
                variant="body1"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Iconify sx={{ mr: 1 }} icon="akar-icons:link-out" /> Proof of
                Address
              </Typography>
            </Box>
          </Card>
          <Card sx={{ p: 3, mb: 3 }}>
            <Box sx={gridStyle}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Wallet Id:
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }} fontSize={12}>
                {user.wallet.uuid}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Auth ID:
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }} fontSize={12}>
                {user.authId}
              </Typography>
              {generalAccountInfo.map((info) => (
                <React.Fragment key={info.label}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {info.label}:
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {info.isDate
                      ? formatDate(user[info.valueLabel] as string)
                      : (user[info.valueLabel] as string)}
                  </Typography>
                </React.Fragment>
              ))}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
