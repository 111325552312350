import { Box, TextField, TextFieldProps } from '@mui/material';
import {
  GridFilterInputValueProps,
  GridFilterOperator,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import useDebounce from 'src/lib/hooks/useDebounce';

function InputDateInterval(props: GridFilterInputValueProps): JSX.Element {
  const { item, applyValue, focusElementRef = null } = props;
  const [filterState, setFilterState] = useState<[string, string]>(['', '']);
  const debouncedFilterState = useDebounce(filterState, 500);

  const handleLowerFilterChange: TextFieldProps['onChange'] = (event) => {
    const newLowerBound = event.target.value;
    setFilterState([newLowerBound, filterState[1]]);
  };

  const handleUpperFilterChange: TextFieldProps['onChange'] = (event) => {
    const newUpperBound = event.target.value;
    setFilterState([filterState[0], newUpperBound]);
  };

  useEffect(() => {
    applyValue({ ...item, value: debouncedFilterState });
  }, [debouncedFilterState]);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <TextField
        name="lower-bound-input"
        placeholder="From"
        label="From"
        variant="standard"
        value={filterState[0]}
        onChange={handleLowerFilterChange}
        type="date"
        inputRef={focusElementRef}
        sx={{ mr: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        name="upper-bound-input"
        placeholder="To"
        label="To"
        variant="standard"
        value={filterState[1]}
        onChange={handleUpperFilterChange}
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
}

const dateRangeOperator: GridFilterOperator[] = [
  {
    label: 'from - to',
    value: 'from_to',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      const from = new Date(filterItem.value[0]);
      const to = new Date(filterItem.value[1]);
      return (params) => {
        const date = new Date(params.value);
        return date >= from && date <= to;
      };
    },
    InputComponent: InputDateInterval,
  },
];

export default dateRangeOperator;
