import { Container } from '@mui/material';
import Regions from 'src/components/modules/Regions/Regions';
import Page from 'src/template/components/Page';

export default function RegionsPage() {
  return (
    <Page title="Regions">
      <Container>
        <Regions />
      </Container>
    </Page>
  );
}
