import useSWR from 'swr';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Fetch } from '../types/fetch';
import { Response } from '../types/response';
import { User, Wallet } from '../types/users';
import generateSearchRequest from '../utils/generateSearchRequest';

/**
 * Fetches a list of users
 * @param {BracketFilterParams} filter - Object that contains the bracket filter parameters
 * @return {Fetch<User>} returns a list of users that follows swr's fetch format plus the total count of users
 */
export const fetchUsers = (filter: BracketFilterParams): Fetch<User[]> => {
  const { data, error } = useSWR<Response<User[]>, Error>(
    `admin/users/${generateSearchRequest(filter)}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Fetches a user
 * @param {string} authId - The user's authId
 * @returns {Fetch<User>} returns a user that follows swr's fetch format
 */
export const fetchUser = (authId: string): Fetch<User> => {
  const { data, error } = useSWR<User, Error>(`admin/users/profile/${authId}`);

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Fetches a user's wallet
 * @param {string} authId - The user's authId
 * @returns {Fetch<Wallet>} returns a user's wallet that follows swr's fetch format
 */
export const fetchUserWallet = (authId: string): Fetch<Wallet> => {
  const { data, error } = useSWR<Wallet, Error>(`admin/users/wallet/${authId}`);

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};
