import useRegionsTable from 'src/components/modules/Regions/RegionsTable/useRegionsTable';
import useSWR, { mutate } from 'swr';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Fetch } from '../types/fetch';
import { LTVRegion } from '../types/ltvRegion';
import { Response } from '../types/response';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

const mutateRegionList = () => {
  const { filter } = useRegionsTable.getState();

  mutate(`admin/regions/${generateSearchRequest(filter)}`);
};

/**
 * Fethes a list of LTV regions
 * @returns {Fetch<LTVRegion>}
 */
export const fetchLTVRegions = (
  filter: BracketFilterParams
): Fetch<LTVRegion[]> => {
  const { data, error } = useSWR<Response<LTVRegion[]>, Error>(
    `admin/regions/${generateSearchRequest(filter)}`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    total: data?.meta.totalCount,
    error: error?.message,
  };
};

export const createRegion = async (region: Partial<LTVRegion>) => {
  const api = await getAPIClient();
  const res = await api.post('admin/regions', region);

  mutateRegionList();
  return res;
};

export const updateRegion = async (region: Partial<LTVRegion>) => {
  const api = await getAPIClient();

  const res = await api.put(`admin/regions/${region.uuid}`, {
    name: region.name,
    code: region.code,
    productFullId: region.productFullId,
    iosStickyValue: region.iosStickyValue,
  });

  mutateRegionList();
  return res;
};

export const deleteRegion = async (uuid: string) => {
  const api = await getAPIClient();
  const res = await api.delete(`admin/regions/${uuid}`);

  mutateRegionList();
  return res;
};

export const uploadRegionsCSV = async (regions: Partial<LTVRegion>[]) => {
  const api = await getAPIClient();
  const res = await api
    .post('admin/regions/csv', { regions })
    .catch((e) => e.response);

  mutateRegionList();
  return {
    error: res.data.error,
    created: res.data.created,
    updated: res.data.updated,
  };
};
