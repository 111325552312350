import { Instagram, Twitter } from '@mui/icons-material';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';

export default function CollectionSocialMedia() {
  return (
    <Card>
      <CardHeader title="Social Media" />
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Card variant="outlined">
            <CardActionArea sx={{ padding: 1 }}>
              <Twitter />
            </CardActionArea>
          </Card>
          <Card variant="outlined">
            <CardActionArea sx={{ padding: 1 }}>
              <Instagram />
            </CardActionArea>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
}
