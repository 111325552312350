// @mui
import { LogoutRounded } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Fade,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from 'src/template/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { user, logout } = useAuth();

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={`${user?.displayName} profile picture`}
        />

        <Box
          flex={1}
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant="subtitle2" noWrap>
              {user?.displayName ?? 'user'}
            </Typography>
            <Fade in={!isCollapse}>
              <Tooltip title="Logout" placement="top">
                <IconButton onClick={logout} sx={{ color: 'text.secondary' }}>
                  <LogoutRounded />
                </IconButton>
              </Tooltip>
            </Fade>
          </Stack>
        </Box>
      </RootStyle>
    </Link>
  );
}
