import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Modal,
  Stack,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import { createRegion, updateRegion } from 'src/lib/services/regions';
import { LTVRegion } from 'src/lib/types/ltvRegion';

type EditOrCreateRegionModalProps = {
  open: boolean;
  onClose: () => void;
  region?: LTVRegion;
};

export default function EditOrCreateRegionModal({
  open,
  onClose,
  region,
}: EditOrCreateRegionModalProps) {
  const onSave = async (values: Partial<LTVRegion>) => {
    if (region) {
      await updateRegion({
        ...region,
        ...values,
      });
    } else {
      await createRegion(values);
    }

    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Card sx={{ width: '400px' }}>
            <CardHeader title={region ? 'Edit Region' : 'Create New Region'} />
            <CardContent>
              <Stack spacing={2} justifyContent="center">
                <Formik
                  initialValues={{
                    code: region?.code || '',
                    name: region?.name || '',
                    productFullId: region?.productFullId || '',
                    iosStickyValue: region?.iosStickyValue || '',
                  }}
                  onSubmit={onSave}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <>
                      <TextField
                        label="Code"
                        variant="outlined"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <TextField
                        label="Product"
                        variant="outlined"
                        name="productFullId"
                        value={values.productFullId}
                        onChange={handleChange}
                      />
                      <TextField
                        label="iOS Value"
                        variant="outlined"
                        name="iosStickyValue"
                        value={values.iosStickyValue}
                        onChange={handleChange}
                      />

                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Button color="error" onClick={onClose}>
                          Cancel
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => handleSubmit()}
                          disabled={
                            !values.code ||
                            !values.name ||
                            !values.productFullId ||
                            !values.iosStickyValue
                          }
                        >
                          Save
                        </Button>
                      </Stack>
                    </>
                  )}
                </Formik>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Fade>
    </Modal>
  );
}
