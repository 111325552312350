import { Container } from '@mui/material';
import Users from 'src/components/modules/Users/Users';
import Page from 'src/template/components/Page';

export default function UsersPage() {
  return (
    <Page title="Users">
      <Container maxWidth="xl">
        <Users />
      </Container>
    </Page>
  );
}
