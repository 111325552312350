import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { Collection } from 'src/lib/types/collection';
import Iconify from 'src/template/components/Iconify';
import {
  TableHeadCustom,
  TableSelectedActions,
} from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';

import CollectionTableRow from './CollectionTableRow';
import useCollectionsTable from './useCollectionsTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'name', label: 'Collection' },
  { id: 'active', label: 'Status' },
  { id: 'nftsCount', label: 'NFTs' },
  { id: 'userUuid', label: 'Creator' },
  { id: 'verified', label: 'Verified' },
  { id: 'reviewStatus', label: 'Review Status', align: 'center' },
  { id: 'updatedAt', label: 'Updated At', align: 'right' },
  { id: 'createdAt', label: 'Created At', align: 'right' },
];

export default function CollectionsTable({
  data,
  loading,
}: {
  data: Collection[] | undefined;
  loading: boolean;
}) {
  const [selected, toggleSelectAll] = useCollectionsTable((state) => [
    state.selected,
    state.toggleSelectAll,
  ]);

  const [order, orderBy] = useCollectionsTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useCollectionsTable((state) => state.onSort);

  const dense = useCollectionsTable((state) => state.dense);

  const onSelectAll = () => {
    toggleSelectAll(data?.map((collection) => collection.uuid) || []);
  };

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      {selected.length > 0 && (
        <TableSelectedActions
          dense={dense}
          numSelected={selected.length}
          rowCount={data?.length || 0}
          onSelectAllRows={onSelectAll}
          actions={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={() => {}}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />
      )}

      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={[...TABLE_HEAD, { id: '', label: '' }]}
          rowCount={loading ? 0 : data && data.length}
          numSelected={selected.length}
          onSort={onSort}
          onSelectAllRows={onSelectAll}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((collection: Collection) => (
              <CollectionTableRow
                key={collection.uuid}
                collection={collection}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
