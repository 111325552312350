export type Payment = {
  uuid: string;
  createdAt: string;
  amount: number;
  status: PaymentStatuses;
  coins: number;
  currency: string;
  scheduleAt?: string;
  excutedAt?: string;
  value?: number;
};

export enum PaymentStatuses {
  requested = 'requested',
  executed = 'executed',
  cancelled = 'cancelled',
  scheduled = 'scheduled',
}
